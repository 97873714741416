import React, { useContext, useState } from "react";
import PropTypes from 'prop-types'
import Button from "../../atom/button/Button";
import Image from "../../atom/image/Image";
import Selectfield from "../../atom/selectfield/Selectfield";
import { Link } from "react-router-dom";
import { AppContext } from "../../../data/ContextProvider";
import Icon from "../../atom/icons/Icon";
import t from "../../../helpers/t9n";
import { getDeliveryDate, renderTitle } from "../../../helpers/utils";

const CartProduct = (props) => {

  const context = useContext(AppContext);
  const [selectedQuantity, setSelectedQuantity] = useState(props.quantity ? props.quantity : 0);
  const [currentStatus] = useState(props.deliveryStatus ? props.deliveryStatus : 1);
  const [isWished, setIsWished] = useState(props.isWished);

  function handleQuantityChange(oldQuantity, quantity, variantId) {
    setSelectedQuantity(quantity)
    props.handleQuantityChange(oldQuantity, quantity, variantId);
  }

  const handleWish = (event) => {
    event.preventDefault();
    if (isWished) {
      // context.handleRemoveFromWishlist(props.pid, props.variantId)
      context.handleRemoveFromWishlist(props.pid)
    } else {
      // context.handleAddToWishlist(props.pid, props.variantId)
      context.handleAddToWishlist(props.pid)
    }
    setIsWished(!isWished);
  };

  const weight = props?.weight_in_kg || 0;
  const large_product = (weight >= 31.5);

  return (
    <div
      className={`cart-product  ${props.isAvailable ? "cart-product--available" : "cart-product--unavailable"}
      ${large_product ? "cart-product--alert" : ""}
      ${props.isWishlistProduct ? "cart-product--wishlist" : ""}
      ${props.isOverviewProduct ? "cart-product--overview" : ""}
      ${props.isOrderDetailProduct ? "cart-product--order-detail" : ""}`}>

      <div className={"cart-product__wrap-separator"}>
        <span className={"cart-product__image"}>
          {props.availability_state === 'Artikel ist ausverkauft' ? (
            <Image type={"fullwidth"} alt={"cart-product-image"} image={props.image} />
          ) : (
          <Link to={`/${window.currentLang}/product/${props.pid}`}>
            <Image type={"fullwidth"} alt={"cart-product-image"} image={props.image} />
          </Link>
          )}
        </span>

        <div className="cart-product__content">
          <span className="cart-product__row">
            <span className="cart-product__title">{renderTitle(props.product)}</span>
          </span>
          <span className={"cart-product__row cart-product__row--detail"}>
            <span className={"cart-product__detail-id"}>{props.id}</span>
            <>
              <span className={"cart-product__detail-qty"}>{t("cart.qty")} {props.quantity}</span>
              <span className={"cart-product__detail-seperator"}>|</span>
              <span className={"cart-product__detail-variant-label"}>{t("cart.variant.label")} </span>
              <span className={"cart-product__detail-variant"}>{props.variant}</span>
              <span className={"cart-product__detail-seperator cart-product__detail-seperator--mobile"}>|</span>
              <span className={"cart-product__detail-price--mobile"}>{props.price} {t("app.points")}</span>
            </>
          </span>
          {!props.isOrderDetailProduct &&
          <span className={"cart-product__row"}>
            <span className={"cart-product__shipping"}>{getDeliveryDate(props.shippingDays)}</span>
          </span>
          }
          {props.isWishlistProduct && !props.isAvailable &&
          <span className={"cart-product__row"}>
            <span className={"cart-product__availability"}>{t("cart.no.available")}</span>
          </span>
          }
          {!props.isOrderDetailProduct &&
          <span className={"cart-product__row"}>
            <span className={"cart-product__price"}>{Number(props.price).toLocaleString()} {t("app.points")}</span>
          </span>
          }
          {!props.isAvailable && !props.isWishlistProduct &&
          <span className={"cart-product__row"}>
            <span className={"cart-product__availability"}>{t("cart.no.available")}</span>
          </span>
          }
          {!props.isWishlistProduct &&
          <span className={"cart-product__row cart-product__row--actions"}>
            {!props.isOrderDetailProduct &&
            <span className={"cart-product__action-wishlist"}>
              <Button type={"noOutline"}
                      text={`${isWished ? t("cart.wishlist.button.remove") : t("cart.wishlist.button.add")}`}
                      icon={`${isWished ? "heart-active" : "heart"}`}
                      onClick={(e) => handleWish(e)} />
            </span>
            }
            <span className={"cart-product__action-seperator"}>|</span>
            {props.isOrderDetailProduct &&
            <span className={"cart-product__detail-qty"}>{t("cart.qty")} {props.quantity}</span>
            }
            <span className={"cart-product__action-delete"}>
            {!props.isOrderDetailProduct &&
            <Button type={"noOutline"} text={t("cart.button.product.remove")} icon={"garbage"}
                    onClick={() => props.removeFromCartCallback(props.variantId)} />
            }
            </span>
          </span>
          }
        </div>
      </div>
      {props.isOrderDetailProduct &&
      <div className={"cart-product__status"}>
        <span className={"cart-product__status-label"}>
          <h5>{t("cart.order.heading")}</h5>&nbsp;&nbsp;
        </span>
        <span className={`cart-product__status-icons`}>
          <Icon icon={"shipment-clock"} additionalClass={`${currentStatus === 1 ? "active" : ""}`} />
          <Icon icon={"shipment-disable"} additionalClass={`${currentStatus === 2 ? "active" : ""}`} />
          <Icon icon={"shipment-check"} additionalClass={`${currentStatus === 3 ? "active" : ""}`} />
        </span>
      </div>
      }
      <div className={"cart-product__quantity"}>
        {props.isWishlistProduct &&
        <>
          <div className={"cart-product__wrap-separator--detail"}>
            <span className={"cart-product__action-wishlist"}>
              <Button type={"noOutline"} text={t("app.put.in.cart")} icon={"shopping-cart-full"}
                      onClick={() => {
                        context.handleAddToCart(props.variantId, selectedQuantity)
                        props.removeFromWishlistCallback(props.pid, props.variantId)
                      }} />
            </span>
            <span className={"cart-product__action-delete"}>
              <Button type={"noOutline"} text={t("cart.button.product.remove")} icon={"garbage"}
                      onClick={props.removeFromWishlistCallback ? () => props.removeFromWishlistCallback(props.pid, props.variantId) : () => context.handleRemoveFromWishlist(props.pid, props.variantId)} />
            </span>
          </div>
        </>
        }

        {props.isOrderDetailProduct &&
        <>
          <span className={"cart-product__price"}>{props.price} {t("app.points")}</span>
            {props.tracking_url && props.tracking_url !== "" &&
              <a target="_blank" rel="noopener noreferrer" href={props.tracking_url}><Button type={"secondary"}
                                                                                        textColor={"black"}
                                                                                        text={t("cart.track.order.button")}
                                                                                        backgroundColor={"bg-white"} /></a>
            }
          </>
        }

        {(!props.isWishlistProduct && !props.isOrderDetailProduct) &&
        <Selectfield isQuantity={true} placeholder={props.quantity}
                     defaultValue={props.quantity}
                     label={props.quantityLabel}
                     state={`${(!props.isAvailable || props.isOverviewProduct) ? "inactive" : "active"}`}
                     onSelect={(e) => {
                       handleQuantityChange(props.quantity, e.target.value, props.variantId)
                     }} />
        }
      </div>
    </div>
  );
}

CartProduct.propTypes = {
  title: PropTypes.string,
  price: PropTypes.number,
  id: PropTypes.string,
  variant: PropTypes.string,
  quantity: PropTypes.number,
  shippingDate: PropTypes.string,
  variantId: PropTypes.number,
  isAvailable: PropTypes.bool,
  isWished: PropTypes.bool,
  isWishlistProduct: PropTypes.bool,
  isOrderDetailProduct: PropTypes.bool,
  deliveryStatus: PropTypes.number,
};
CartProduct.defaultProps = {
  isAvailable: true,
  isWishlistProduct: false,
  isOrderDetailProduct: false,
  isWished: false,
};


export default CartProduct;
